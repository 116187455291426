
const levelDic = (level) => {
  switch (level) {
    case 1:
      return 1;

    case 2:
      return 3;

    case 3:
      return 5;

    case 4:
      return 11;

    case 5:
      return 17;

    case 6:
      return 23;

    case 7:
      return 29;

    case 8:
      return 35;

    case 9:
      return 41;

    case 10:
      return 47;

    case 11:
      return 53;

    default:
      return 0;
  }
}

export default levelDic