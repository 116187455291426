import map1 from "../assets/img/mapas/1.png";
import map2 from "../assets/img/mapas/2.png";
import map3 from "../assets/img/mapas/3.png";
import map4 from "../assets/img/mapas/4.png";
import map5 from "../assets/img/mapas/5.png";
import map6 from "../assets/img/mapas/6.png";
import map7 from "../assets/img/mapas/7.png";
import map8 from "../assets/img/mapas/8.png";
import map9 from "../assets/img/mapas/9.png";
import map10 from "../assets/img/mapas/10.png";
import map11 from "../assets/img/mapas/11.png";
import map12 from "../assets/img/mapas/12.png";

export const maps = {
  1: map1,
  3: map2,
  5: map3,
  11: map4,  
  17: map5,
  23: map6,
  29: map7,
  35: map8,
  41: map9,
  47: map10,
  53: map11,
  59: map12,
}