import { Button, Flex, Modal, Space, Typography, InputNumber } from "antd";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import girl from "../../assets/img/svg/girl.svg";
import { useState } from "react";
import levelDic from "../../utils/levelDic";
const { Title, Text } = Typography;

const Index = () => {
  const desktop = useMediaQuery("(min-width: 1020px)");
  const [modal, setModal] = useState(false);
  const [level, setLevel] = useState(1);

  const handleOk = () => {
    localStorage.setItem("actualPhase", levelDic(level));
    setModal(false);
    window.location.href = "/jogos";
  };
  const handleCancel = () => {
    setModal(false);
  };
  const onChange = (value) => {
    setLevel(value);
  };

  return (
    <div
      style={{
        padding: "1rem",
        margin: desktop ? "0 2rem" : "0 1rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: desktop ? "row" : "column",
      }}
    >
      <Modal
        open={modal}
        title="Escolha um nível para começar"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Ir
          </Button>,
        ]}
      >
        <Space direction="vertical">
          <Text>Escolha um nível para começar</Text>
          <InputNumber min={1} max={11} defaultValue={1} onChange={onChange} />
        </Space>
      </Modal>
      <Space direction="vertical">
        <Title level={3}>Bem-vindo(a) à Revolução das Letras!</Title>
        {desktop ? (
          <Flex gap="middle" justify="flex-start" style={{ width: "50%" }}>
            <Text>
              Descubra uma nova forma de aprendizado interativo e divertido para
              crianças em fase de alfabetização - nossa plataforma educacional
              oferece vídeos e jogos envolventes para tornar a alfabetização uma
              jornada empolgante e inclusiva. Experimente agora mesmo!
            </Text>
          </Flex>
        ) : (
          <Text>
            Descubra uma nova forma de aprendizado interativo e divertido para
            crianças em fase de alfabetização - nossa plataforma educacional
            oferece vídeos e jogos envolventes para tornar a alfabetização uma
            jornada empolgante e inclusiva. Experimente agora mesmo!
          </Text>
        )}

        <Space direction="horizontal" style={{ marginTop: "1rem" }}>
          <Button
            type="primary"
            size="large"
            style={{ backgroundColor: "#001529" }}
            onClick={() => setModal(true)}
          >
            Jogar
          </Button>
        </Space>
      </Space>

      {desktop && <img src={girl} alt="placeholder" style={{ order: 2 }} />}
    </div>
  );
};

export default Index;
