import a from '../../../assets/img/letras/a.png'
import e from '../../../assets/img/letras/e.png'
import i from '../../../assets/img/letras/i.png'
import o from '../../../assets/img/letras/o.png'
import u from '../../../assets/img/letras/u.png'
import w from '../../../assets/img/letras/w.png'
import y from '../../../assets/img/letras/y.png'
/// #region words
import meninoW from '../../../assets/img/letras/meninoW.png'
import malaW from '../../../assets/img/letras/malaW.png'
import noiteW from '../../../assets/img/letras/noiteW.png'
import bebeW from '../../../assets/img/letras/bebeW.png'
import bolaW from '../../../assets/img/letras/bolaW.png'
import bananaW from '../../../assets/img/letras/bananaW.png'
import velaW from '../../../assets/img/letras/velaW.png'
import arara from '../../../assets/img/letras/arara.png'
import escada from '../../../assets/img/letras/escada.png'
import indio from '../../../assets/img/letras/indio.png'
import ovo from '../../../assets/img/letras/ovo.png'
import urso from "../../../assets/img/letras/urso.png"
import yuri from "../../../assets/img/letras/zey.png"
import walace from "../../../assets/img/letras/zew.png"
import mala from '../../../assets/img/letras/mala.png'
import oitoW from '../../../assets/img/letras/oitoW.png'
import caixaW from '../../../assets/img/letras/caixaW.png'
import luaW from '../../../assets/img/letras/luaW.png'
import luvaW from '../../../assets/img/letras/luvaW.png'
import janelaW from '../../../assets/img/letras/janelaW.png'
import panelaW from '../../../assets/img/letras/panelaW.png'
import pijamaW from '../../../assets/img/letras/pijamaW.png'
import dedoW from '../../../assets/img/letras/dedoW.png'
import dominoW from '../../../assets/img/letras/dominoW.png'
import focaW from '../../../assets/img/letras/focaW.png'
import galoW from '../../../assets/img/letras/galoW.png'
import geloW from '../../../assets/img/letras/geloW.png'
import zebraW from '../../../assets/img/letras/zebraW.png'
import hipopotamoW from '../../../assets/img/letras/hipopotamoW.png'
import ratoW from '../../../assets/img/letras/ratoW.png'
import queijoW from '../../../assets/img/letras/queijoW.png'
import tesouraW from '../../../assets/img/letras/tesouraW.png'
import queroW from '../../../assets/img/letras/queroW.png'
import rioW from '../../../assets/img/letras/rioW.png'
import terraW from '../../../assets/img/letras/terraW.png'
import saxofoneW from '../../../assets/img/letras/saxofoneW.png'
import sorrirW from '../../../assets/img/letras/sorrirW.png'
import assistirW from '../../../assets/img/letras/assistirW.png'
import assoprarW from '../../../assets/img/letras/assoprarW.png'
import paçocaW from '../../../assets/img/letras/paçocaW.png'
import xicaraW from '../../../assets/img/letras/xicaraW.png'
import xadrezW from '../../../assets/img/letras/xadrezW.png'
import chutarW from '../../../assets/img/letras/chutarW.png'
import chorarW from '../../../assets/img/letras/chorarW.png'
import bolinhasW from '../../../assets/img/letras/bolinhasW.png'
import chineloW from '../../../assets/img/letras/chineloW.png'
import chocolateW from '../../../assets/img/letras/chocolateW.png'
import gatinhoW from '../../../assets/img/letras/gatinhoW.png'
import galinhaW from '../../../assets/img/letras/galinhaW.png'
/// #endregion
// #region images
import menino from '../../../assets/img/letras/menino.png'
import noite from '../../../assets/img/letras/noite.png'
import bebe from '../../../assets/img/letras/bebe.png'
import bola from '../../../assets/img/letras/bola.png'
import banana from '../../../assets/img/letras/banana.png'
import oito from '../../../assets/img/letras/oito.png'
import vela from '../../../assets/img/letras/vela.png'
import caixa from '../../../assets/img/letras/caixa.png'
import lua from '../../../assets/img/letras/lua.png'
import luva from '../../../assets/img/letras/luva.png'
import janela from '../../../assets/img/letras/janela.png'
import panela from '../../../assets/img/letras/panela.png'
import pijama from '../../../assets/img/letras/pijama.png'
import dedo from '../../../assets/img/letras/dedo.png'
import domino from '../../../assets/img/letras/domino.png'
import foca from '../../../assets/img/letras/foca.png'
import galo from '../../../assets/img/letras/galo.png'
import gelo from '../../../assets/img/letras/gelo.png'
import zebra from '../../../assets/img/letras/zebra.png'
import hipopotamo from '../../../assets/img/letras/hipopotamo.png'
import rato from '../../../assets/img/letras/rato.png'
import queijo from '../../../assets/img/letras/queijo.png'
import tesoura from '../../../assets/img/letras/tesoura.png'
import quero from '../../../assets/img/letras/quero.png'
import rio from '../../../assets/img/letras/rio.png'
import terra from '../../../assets/img/letras/terra.png'
import saxofone from '../../../assets/img/letras/saxofone.png'
import sorrir from '../../../assets/img/letras/sorrir.png'
import assistir from '../../../assets/img/letras/assistir.png'
import assoprar from '../../../assets/img/letras/assoprar.png'
import paçoca from '../../../assets/img/letras/paçoca.png'
import xicara from '../../../assets/img/letras/xicara.png'
import xadrez from '../../../assets/img/letras/xadrez.png'
import chutar from '../../../assets/img/letras/chutar.png'
import chorar from '../../../assets/img/letras/chorar.png'
import bolinhas from '../../../assets/img/letras/bolinhas.png'
import chinelo from '../../../assets/img/letras/chinelo.png'
import chocolate from '../../../assets/img/letras/chocolate.png'
import gatinho from '../../../assets/img/letras/gatinho.png'
import galinha from '../../../assets/img/letras/galinha.png'

// #endregion
import soundA from '../../../assets/sounds/memory/a.mp3'
import soundE from '../../../assets/sounds/memory/e.mp3'
import soundI from '../../../assets/sounds/memory/i.mp3'
import soundO from '../../../assets/sounds/memory/o.mp3'
import soundU from '../../../assets/sounds/memory/u.mp3'
import soundW from '../../../assets/sounds/memory/w.mp3'
import soundY from '../../../assets/sounds/memory/y.mp3'
import soundMala from '../../../assets/sounds/memory/mala.mp3'
import soundMenino from '../../../assets/sounds/memory/menino.mp3'
import soundNoite from '../../../assets/sounds/memory/noite.mp3'
import soundBebe from '../../../assets/sounds/memory/bebe.mp3'
import soundBola from '../../../assets/sounds/memory/bola.mp3'
import soundBanana from '../../../assets/sounds/memory/banana.mp3'
import soundArara from '../../../assets/sounds/memory/arara.mp3'
import soundEscada from '../../../assets/sounds/memory/escada.mp3'
import soundIndio from '../../../assets/sounds/memory/indio.mp3'
import soundOvo from '../../../assets/sounds/memory/ovo.mp3'
import soundUrso from '../../../assets/sounds/memory/urso.mp3'
import soundWallace from '../../../assets/sounds/memory/wallace.mp3'
import soundYuri from '../../../assets/sounds/memory/yuri.mp3'
import soundOito from '../../../assets/sounds/memory/oito.mp3'
import soundVela from '../../../assets/sounds/memory/vela.mp3'
import soundCaixa from '../../../assets/sounds/memory/caixa.mp3'
import soundLua from '../../../assets/sounds/memory/lua.mp3'
import soundLuva from '../../../assets/sounds/memory/luva.mp3'
import soundJanela from '../../../assets/sounds/memory/janela.mp3'
import soundPanela from '../../../assets/sounds/memory/panela.mp3'
import soundPijama from '../../../assets/sounds/memory/pijama.mp3'
import soundDedo from '../../../assets/sounds/memory/dedo.mp3'
import soundDomino from '../../../assets/sounds/memory/domino.mp3'
import soundFoca from '../../../assets/sounds/memory/foca.mp3'
import soundGalo from '../../../assets/sounds/memory/galo.mp3'
import soundGelo from '../../../assets/sounds/memory/gelo.mp3'
import soundZebra from '../../../assets/sounds/memory/zebra.mp3'
import soundHipopotamo from '../../../assets/sounds/memory/hipopotamo.mp3'
import soundRato from '../../../assets/sounds/memory/rato.mp3'
import soundQueijo from '../../../assets/sounds/memory/queijo.mp3'
import soundTesoura from '../../../assets/sounds/memory/tesoura.mp3'
import soundQuero from '../../../assets/sounds/memory/quero.mp3'
import soundRio from '../../../assets/sounds/memory/rio.mp3'
import soundTerra from '../../../assets/sounds/memory/terra.mp3'
import soundSaxofone from '../../../assets/sounds/memory/saxofone.mp3'
import soundSorrir from '../../../assets/sounds/memory/sorrir.mp3'
import soundAssistir from '../../../assets/sounds/memory/assistir.mp3'
import soundAssoprar from '../../../assets/sounds/memory/assoprar.mp3'
import soundPaçoca from '../../../assets/sounds/memory/paçoca.mp3'
import soundXicara from '../../../assets/sounds/memory/xicara.mp3'
import soundXadrez from '../../../assets/sounds/memory/xadrez.mp3'
import soundChutar from '../../../assets/sounds/memory/chutar.mp3'
import soundChorar from '../../../assets/sounds/memory/chorar.mp3'
import soundBolinhas from '../../../assets/sounds/memory/bolinhas.mp3'
import soundChinelo from '../../../assets/sounds/memory/chinelo.mp3'
import soundChocolate from '../../../assets/sounds/memory/chocolate.mp3'
import soundGatinho from '../../../assets/sounds/memory/gatinho.mp3'
import soundGalinha from '../../../assets/sounds/memory/galinha.mp3'



export const memoryLetters = {
  9: {
    letters: [
      { letter: "A", img: a, sound: soundA },
      { letter: "E", img: e, sound: soundE },
      { letter: "I", img: i, sound: soundI },
      { letter: "O", img: o, sound: soundO },
      { letter: "U", img: u, sound: soundU },
      { letter: "Y", img: y, sound: soundY },
      { letter: "W", img: w, sound: soundW }
    ],
    images: [
      { letter: "A", img: arara, sound: soundArara },
      { letter: "E", img: escada, sound: soundEscada },
      { letter: "I", img: indio, sound: soundIndio },
      { letter: "O", img: ovo, sound: soundOvo },
      { letter: "U", img: urso, sound: soundUrso },
      { letter: "Y", img: yuri, sound: soundYuri },
      { letter: "W", img: walace, sound: soundWallace }
    ]
  },
  15: {
    letters: [
      { letter: "MENINO", img: meninoW, sound: soundMenino },
      { letter: "MALA", img: malaW, sound: soundMala },
      { letter: "NOITE", img: noiteW, sound: soundNoite },
      { letter: "BEBE", img: bebeW, sound: soundBebe },
      { letter: "BOLA", img: bolaW, sound: soundBola },
      { letter: "BANANA", img: bananaW, sound: soundBanana },
    ],
    images: [
      { letter: "MENINO", img: menino, sound: soundMenino },
      { letter: "MALA", img: mala, sound: soundMala },
      { letter: "NOITE", img: noite, sound: soundNoite },
      { letter: "BEBE", img: bebe, sound: soundBebe },
      { letter: "BOLA", img: bola, sound: soundBola },
      { letter: "BANANA", img: banana, sound: soundBanana },
    ]
  },
  21: {
    letters: [
      { letter: "OITO", img: oitoW, sound: soundOito },
      { letter: "VELA", img: velaW, sound: soundVela },
      { letter: "CAIXA", img: caixaW, sound: soundCaixa },
      { letter: "LUA", img: luaW, sound: soundLua },
      { letter: "LUVA", img: luvaW, sound: soundLuva }
    ],
    images: [
      { letter: "OITO", img: oito, sound: soundOito },
      { letter: "VELA", img: vela, sound: soundVela },
      { letter: "CAIXA", img: caixa, sound: soundCaixa },
      { letter: "LUA", img: lua, sound: soundLua },
      { letter: "LUVA", img: luva, sound: soundLuva }
    ]
  },
  27: {
    letters: [
      { letter: "JANELA", img: janelaW, sound: soundJanela },
      { letter: "PANELA", img: panelaW, sound: soundPanela },
      { letter: "PIJAMA", img: pijamaW, sound: soundPijama },
      { letter: "ZEBRA", img: zebraW, sound: soundZebra },
      { letter: "HIPOPOTAMO", img: hipopotamoW, sound: soundHipopotamo }
    ],
    images: [
      { letter: "JANELA", img: janela, sound: soundJanela },
      { letter: "PANELA", img: panela, sound: soundPanela },
      { letter: "PIJAMA", img: pijama, sound: soundPijama },
      { letter: "ZEBRA", img: zebra, sound: soundZebra },
      { letter: "HIPOPOTAMO", img: hipopotamo, sound: soundHipopotamo }
    ]
  },
  33: {
    letters: [
      { letter: "DEDO", img: dedoW, sound: soundDedo },
      { letter: "DOMINO", img: dominoW, sound: soundDomino },
      { letter: "FOCA", img: focaW, sound: soundFoca },
      { letter: "GALO", img: galoW, sound: soundGalo },
      { letter: "GELO", img: geloW, sound: soundGelo }
    ],
    images: [
      { letter: "DEDO", img: dedo, sound: soundDedo },
      { letter: "DOMINO", img: domino, sound: soundDomino },
      { letter: "FOCA", img: foca, sound: soundFoca },
      { letter: "GALO", img: galo, sound: soundGalo },
      { letter: "GELO", img: gelo, sound: soundGelo }
    ]
  },
  39: {
    letters: [
      { letter: "RATO", img: ratoW, sound: soundRato },
      { letter: "QUEIJO", img: queijoW, sound: soundQueijo },
      { letter: "TESOURA", img: tesouraW, sound: soundTesoura },
      { letter: "QUERO", img: queroW, sound: soundQuero },
      { letter: "RIO", img: rioW, sound: soundRio },
      { letter: "TERRA", img: terraW, sound: soundTerra }
    ],
    images: [
      { letter: "RATO", img: rato, sound: soundRato },
      { letter: "QUEIJO", img: queijo, sound: soundQueijo },
      { letter: "TESOURA", img: tesoura, sound: soundTesoura },
      { letter: "QUERO", img: quero, sound: soundQuero },
      { letter: "RIO", img: rio, sound: soundRio },
      { letter: "TERRA", img: terra, sound: soundTerra }
    ]
  },
  45: {
    letters: [
      { letter: "SAXOFONE", img: saxofoneW, sound: soundSaxofone },
      { letter: "SORRIR", img: sorrirW, sound: soundSorrir },
      { letter: "ASSISTIR", img: assistirW, sound: soundAssistir },
      { letter: "ASSOPRAR", img: assoprarW, sound: soundAssoprar },
      { letter: "PAÇOCA", img: paçocaW, sound: soundPaçoca }
    ],
    images: [
      { letter: "SAXOFONE", img: saxofone, sound: soundSaxofone },
      { letter: "SORRIR", img: sorrir, sound: soundSorrir },
      { letter: "ASSISTIR", img: assistir, sound: soundAssistir },
      { letter: "ASSOPRAR", img: assoprar, sound: soundAssoprar },
      { letter: "PAÇOCA", img: paçoca, sound: soundPaçoca }
    ]
  },
  51: {
    letters: [
      { letter: "XICARA", img: xicaraW, sound: soundXicara },
      { letter: "XADREZ", img: xadrezW, sound: soundXadrez },
      { letter: "CHUTAR", img: chutarW, sound: soundChutar },
      { letter: "CHORAR", img: chorarW, sound: soundChorar }
    ],
    images: [
      { letter: "XICARA", img: xicara, sound: soundXicara },
      { letter: "XADREZ", img: xadrez, sound: soundXadrez },
      { letter: "CHUTAR", img: chutar, sound: soundChutar },
      { letter: "CHORAR", img: chorar, sound: soundChorar }
    ]
  },
  57: {
    letters: [
      { letter: "BOLINHAS", img: bolinhasW, sound: soundBolinhas },
      { letter: "CHINELO", img: chineloW, sound: soundChinelo },
      { letter: "CHOCOLATE", img: chocolateW, sound: soundChocolate },
      { letter: "GATINHO", img: gatinhoW, sound: soundGatinho },
      { letter: "GALINHA", img: galinhaW, sound: soundGalinha }
    ],
    images: [
      { letter: "BOLINHAS", img: bolinhas, sound: soundBolinhas },
      { letter: "CHINELO", img: chinelo, sound: soundChinelo },
      { letter: "CHOCOLATE", img: chocolate, sound: soundChocolate },
      { letter: "GATINHO", img: gatinho, sound: soundGatinho },
      { letter: "GALINHA", img: galinha, sound: soundGalinha }
    ]
  }
}